import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/layout/seo"
import Hero from "../components/Global/secondLevelHero/secondLevelHero"
import Footnotes from "../components/Global/footnotes/footnotes"
import Footer from "../components/layout/footer"
import Layout from "../components/layout/layout"


const CookiePolicy = ({ data }) => {

  const content = data.allContentfulCookiePolicyPage.nodes[0]

  const mainContent = content.content.content;

  let bannerData = {
    breadcrumbs: content.hero.breadcrumb,
    title: content.hero.title,
    content: content.hero.content,
    backgroundImage: content.hero.backgroundImage,
  }

  let footnotesData = {
    pageJobCode: content.jobCodeDate,
    footnotesData: [],
  }

  return (
    <Layout>
      <Seo title="Cookie policy" />
      <Hero data={bannerData} />
      <div className="legal__content" dangerouslySetInnerHTML={{ __html: mainContent }} />
      <Footnotes data={footnotesData} />
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
    query {
      allContentfulCookiePolicyPage {
        nodes {
          title
          hero {
            breadcrumb {
              title
              url
            }
            title
            content {
              childMarkdownRemark {
                html
              }
            }
            backgroundImage {
              gatsbyImageData(
                layout: FULL_WIDTH
              )
              title
            }
          }
          content {
            content
          }
          jobCodeDate
        }
      }
    }
`


export default CookiePolicy